import 'core-js/es/array/iterator';
import 'lazysizes';

// Import our CSS
import 'prismjs/themes/prism.css'
import styles from '../scss/app.scss';

//Import vue components
import { VueAgile } from 'vue-agile'
import Prism from 'vue-prismjs'
import ResponsiveBrowser from './components/ResponsiveBrowser.vue'
import CookiePopup from './components/CookiePopup.vue'
import { Youtube } from 'vue-youtube'
import Vuex from 'vuex'
import AOS from 'aos';
// import VueEqualHeights from './plugins/EqualHeights'

// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

	//Enable Equal Heights plugin
	// Vue.use(VueEqualHeights)

	//Enable Vuex store
	Vue.use(Vuex)

	//Enable AOS CSS animation
	Vue.use(AOS.init({
		easing: 'ease-in-out',
		delay: 100,
		offset: 35,
		once: true,
		startEvent: 'load',
		anchorPlacement: 'top-bottom'
	}))

	const store = new Vuex.Store({
		state: {
			isMobileMenuOpen: false,
			scrollPosition: 0,
			callMeOpen: false,
			csrfToken: ''
		},
		mutations: {
			closeMobileMenu(state) {
				state.isMobileMenuOpen = false
			},
			openMobileMenu(state) {
				state.isMobileMenuOpen = true
			},
			adjustScrollPosition(state, position) {
				state.scrollPosition = position
			},
			setCallMeState(state, value) {
				state.callMeOpen = value
			},
			setCsrfToken(state, token) {
				state.csrfToken = token
			},
		}
	});

	// Create our vue instance
	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],
		store,
		components: {
			agile: VueAgile,
			Prism,
			ResponsiveBrowser,
			Youtube,
			CookiePopup,
			'CallMe': () => import(/* webpackChunkName: "CallMe" */ './components/CallMe.vue'),
			'StickyContact': () => import(/* webpackChunkName: "StickyContact" */ './components/StickyContact.vue'),
		},
		data: {
		},
		methods: {
			openCallMe: function () {
				store.commit('setCallMeState', true)
			},
			toggleMobileMenu: function (event) {

				switch (store.state.isMobileMenuOpen) {
					case true:
						store.commit('closeMobileMenu')
						break

					case false:
						store.commit('openMobileMenu')
						break

					default:
						store.commit('closeMobileMenu')
						break
				}

			},

			handleScroll: function (event) {
				const app = document.getElementById('app')
				let scrollPosY = window.pageYOffset | document.body.scrollTop;

				if (scrollPosY > 70) {

					if (store.state.scrollPosition > scrollPosY) {
						if (!app.classList.contains('scrolled--show')) {
							app.classList.add('scrolled--show')
						}
					} else {

						if (app.classList.contains('scrolled--show')) {
							app.classList.remove('scrolled--show')
						}

						if (!app.classList.contains('scrolled')) {
							app.classList.add('scrolled')
						}


					}

				} else if (scrollPosY <= 100) {
					app.classList.remove('scrolled--show')
					app.classList.remove('scrolled')
				}

				store.state.scrollPosition = scrollPosY;
			}
		},
		computed: {
			isMobileMenuOpen() {
				return store.state.isMobileMenuOpen
			},
			isCallMeOpen() {
				return store.state.callMeOpen
			}
		},
		created() {
			window.addEventListener('scroll', this.handleScroll);
			const links = document.querySelectorAll('[href="#reset"]');
		},
		beforeMount: function () {
			store.commit('setCsrfToken', window.siteData.csrf)
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		mounted() {

		},
	});

	return vm;
};

// Execute async function
main().then((vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
